.moveable-box {
  padding: 8px 16px;
  border: solid 1px transparent;
}

.moveable-box:hover {
  border: solid 1px green;
  border-radius: 4px;
  cursor: move;
  background: rgba(0, 0, 0, 0.1);
}
