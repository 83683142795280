.select_container {
  width: 100%;
  max-width: 60%;
  border: solid 1px #ccc;
  overflow: auto;
  position: relative;
}

@media (max-width: 1023px) {
  .select_container {
    max-width: 100%;
  }
}
